<template>
  <div id="development-scroll" class="story-page full-screen-background">
    <div class="page-content background-effect">
      <div class="page-title">Welcome</div>
      <div class="page-details">
        <p>
          I am an experienced full-stack developer, specializing in Node.js and
          Vue. With a deep passion for creating cutting-edge web applications, I
          have harnessed the power of these technologies to craft awe-inspiring
          digital solutions.
        </p>

        <p>
          My proficiency extends to both server-side wizardry with Node.js and
          crafting elegant and interactive front-end experiences using Vue. I
          take pride in my ability to deliver seamless user experiences that are
          not only visually captivating but also highly efficient.
        </p>

        <p>
          Within my portfolio, you will discover an array of projects that
          showcase my technical prowess, innovation, and meticulous attention to
          detail. Each endeavor is a testament to my commitment to excellence
          and my unwavering pursuit of perfection in every aspect of my work.
        </p>

        <p>
          My approach to every project is guided by a strong sense of
          professionalism and a constant thirst for knowledge. I see each
          opportunity as an adventure, and I relish the challenge of staying
          ahead of industry trends and emerging technologies.
        </p>

        <p>
          If you're here to explore my work or collaborate on your next venture,
          I extend a warm welcome to you. Together, let's embark on a journey of
          creating remarkable digital experiences that push the boundaries of
          what's possible with Node.js and Vue.
        </p>

        <p>
          Feel free to browse through my projects, get in touch, and let's make
          something extraordinary - fueled by the artistry of Node.js and Vue!
          🌌🔥
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/story-pages.scss";
.story-page {
  background-image: url("@/assets/dev-bg.jpg");
}
</style>
